@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';











































.evaluations-report-filter__selection {
  max-width: 40rem;
}
